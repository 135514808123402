<template>
  <div class="wrap-edit">
    <el-card>
      <FloatTitBtn :class="!sblxdisabled2 ? 'wr-else' : ''">
        <div class="edit-header">
          <span>野生动物申请表</span>
          <el-tooltip
            :disabled="!saving"
            popper-class="saving-tip"
            content="保存中"
            placement="left"
          >
            <div>
              <el-button
                type="primary"
                v-show="savebuttonshow"
                @click="onSubmit"
                :loading="saving"
                >{{ saving ? "" : "受理" }}</el-button
              >
            </div>
          </el-tooltip>
        </div>
      </FloatTitBtn>

      <div class="edit-container">
        <el-form
          ref="baseform"
          :label-position="labelPosition"
          :model="form"
          label-width="120px"
        >
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="总编号" class="asterisk" prop="ZBH">
                <el-input v-model.trim="form.ZBH" disabled></el-input>
              </el-form-item>
              <el-form-item label="所属分局" class="asterisk" prop="SSFJ">
                <el-select
                  class="input-width"
                  :disabled="sblxdisabled"
                  v-model.trim="form.SSFJ"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options1"
                    :disabled="sblxdisabled"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="案卷号" class="asterisk" prop="AJH">
                <el-input v-model.trim="form.AJH" disabled></el-input>
              </el-form-item>
              <el-form-item label="受理情况" class="asterisk" prop="SLQK">
                <el-select
                  class="input-width"
                  :disabled="sblxdisabled2"
                  v-model.trim="form.SLQK"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options2"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="申报类型" class="asterisk" prop="SBLX">
                <el-select
                  filterable
                  @change="createzbh"
                  :disabled="isSblxDisabled"
                  class="input-width"
                  v-model.trim="form.SBLX"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options4"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="目的" class="asterisk" prop="XMMC">
                <el-input
                  v-model.trim="form.XMMC"
                  :disabled="sblxdisabled"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所属乡镇" prop="XZQHDM">
                <el-select
                  filterable
                  :disabled="sblxdisabled"
                  class="input-width"
                  clearable
                  v-model.trim="form.XZQHDM"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options5"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地点" class="asterisk" prop="JSWZ">
                <el-input
                  v-model.trim="form.JSWZ"
                  :disabled="sblxdisabled"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="申请人" class="asterisk" prop="LXR">
                <el-input
                  v-model.trim="form.LXR"
                  :disabled="sblxdisabled"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="手机号" class="asterisk" prop="SJH">
                <el-input
                  v-model.trim="form.SJH"
                  :disabled="sblxdisabled"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="证件类型" prop="ZJMC">
                <el-select
                  class="input-width"
                  :disabled="sblxdisabled"
                  v-model.trim="form.ZJMC"
                  placeholder="请选择"
                >
                  <el-option-group
                    v-for="group in options3"
                    :key="group.label"
                    :label="group.label"
                  >
                    <el-option
                      v-for="item in group.options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
              <el-form-item label="接件人" prop="JJR">
                <el-input v-model.trim="form.JJRXM" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="证件号码" prop="ZJHM">
                <el-input
                  v-model.trim="form.ZJHM"
                  :disabled="sblxdisabled || !form.ZJMC"
                ></el-input>
              </el-form-item>
              <el-form-item label="接件日期" prop="JJRQ">
                <el-date-picker
                  :disabled="sblxdisabled"
                  style="width: 100%"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                  v-model="form.JJRQ"
                  placeholder="请选择接件日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="期限" prop="QXKSSJ">
                <el-date-picker
                  disabled
                  style="width: 100%"
                  v-model="form.QXKSSJ"
                  placeholder="请选择接件日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="--" prop="QXJSSJ">
                <el-date-picker
                  style="width: 100%"
                  v-model="form.QXJSSJ"
                  placeholder="请选择接件日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注" prop="BZ">
                <el-input
                  v-model.trim="form.BZ"
                  :disabled="sblxdisabled"
                  type="textarea"
                  :rows="3"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
    <div style="width: 100%; height: 1rem"></div>
    <el-card>
      <div
        style="
          display: flex;
          justify-content: space-between;
          margin: 0, 0.4rem;
          padding: 0.5rem;
          border-bottom: 1px solid #999999;
        "
      >
        <div style="font-size: 18px">涉及物种</div>
        <el-button type="primary" @click="toAdd()"> 添加</el-button>
      </div>
      <el-table :data="tableData" height="250" border style="width: 100%">
        <el-table-column
          prop="WZXM"
          label="物种名称(中文名、拉丁学名)"
          width="180"
        >
        </el-table-column>
        <el-table-column prop="BHDJ" label="保护级别" width="180">
        </el-table-column>
        <el-table-column prop="PM" label="品名"> </el-table-column>
        <el-table-column prop="DWGG" label="单位或规格" width="180">
        </el-table-column>
        <el-table-column prop="SL" label="数量" width="180"> </el-table-column>
        <el-table-column prop="BSSLSL" label="标识申领数量" width="180">
        </el-table-column>
        <el-table-column
          prop="LYZM"
          label="来源证明文件及文号/自繁的填写当前养殖场存栏量"
          width="180"
        >
        </el-table-column>
        <el-table-column prop="name" label="操作" width="180" fixed="right">
          <template #default="scope">
            <el-button size="small" @click="toEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button size="small" @click="delRow(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 弹框 -->
      <el-dialog v-model="dialogFormVisible" :title="dialogTitle" width="30%">
        <el-form :model="tempRow" label-width="120px">
          <el-form-item label="物种名称">
            <el-input v-model="tempRow.WZXM"></el-input>
          </el-form-item>
          <el-form-item label="保护级别">
            <el-input v-model="tempRow.BHDJ"></el-input>
          </el-form-item>
          <el-form-item label="品名">
            <el-input v-model="tempRow.PM"></el-input>
          </el-form-item>
          <el-form-item label="单位或规格">
            <el-input v-model="tempRow.DWGG"></el-input>
          </el-form-item>
          <el-form-item label="数量">
            <el-input v-model.number="tempRow.SL"></el-input>
          </el-form-item>
          <el-form-item label="标识申领数量">
            <el-input v-model="tempRow.BSSLSL"></el-input>
          </el-form-item>
          <el-form-item label="来源证明文件及文号">
            <el-input v-model="tempRow.LYZM"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取消</el-button>
            <el-button type="primary" @click="sureEdit()">确认</el-button>
          </span>
        </template>
      </el-dialog>
    </el-card>
  </div>
</template>
<script>
import { ElMessage, ElMessageBox } from "element-plus";
import { toRaw } from "vue";
import mgr from "@/services/security.js";
import FloatTitBtn from "@/components/FloatTitBtn";
export default {
  name:'ysdwsqb',
  components: {
    FloatTitBtn,
  },
  data() {
    return {
      // 弹框标题
      dialogTitle: "",
      // 编辑的行
      editIndex: null,
      // 弹框是否显示
      dialogFormVisible: false,
      // 临时编辑的行
      tempRow: {
        ID: "",
        BJID: "",
        WZXM: "",
        BHDJ: "",
        PM: "",
        DWGG: "",
        SL: null,
        BSSLSL: "",
        LYZM: "",
        JBR: "",
        JBSJ: "",
      },
      tableData: [],
      labelPosition: "left",
      form: {
        Id: "",
        ZBH: "",
        AJH: "",
        SSFJ: "",
        SLQK: true,
        SBLX: "",
        JSDW: "",
        XMDM: "",
        XMMC: "",
        XZQHDM: "",
        JSWZ: "",
        LXR: "",
        SJH: "",
        DZYJ: "",
        DH: "",
        DZ: "",
        FRDB: "",
        DLR: "",
        ZJMC: "",
        ZJHM: "",
        JJRXM: "",
        JJRQ: new Date(),
        BZ: "",
        QXKSSJ:"",
        QXJSSJ:""
      },
      saving: false,
      options1: [],
      options2: [
        {
          value: true,
          label: "受理",
        },
        {
          value: false,
          label: "不受理",
        },
      ],
      options3: [],
      options4: [],
      options5: [],
      sblxdisabled: false,
      sblxdisabled2: true,
      isSblxDisabled: true,
      savebuttonshow: true,
      restaurants: [],
    };
  },
  methods: {
    // 弹框按钮
    sureEdit() {
      if (this.form.Id == null) {
        // 确定编辑
        // 赋值给正在编辑的行
        if (this.dialogTitle === "编辑") {
          // console.log("2222222", this.tableData[this.editIndex]);
          // console.log("3333333333", this.tempRow);
          this.tableData[this.editIndex] = this.tempRow;
        } else {
          // 新增
          // console.log("数据111111", this.tableData);
          // console.log("11111111111",  Array.isArray(this.tableData) );
          this.tableData.unshift({
            ...this.tempRow,
          });
          // console.log("数据2222222",this.tableData);
        }
        // 关闭dialog
        this.dialogFormVisible = false;
      } else {
        // 确定编辑
        // 赋值给正在编辑的行
        if (this.dialogTitle === "编辑") {
          // console.log("2222222", this.tableData[this.editIndex]);
          // console.log("3333333333", this.tempRow);
           this.tableData[this.editIndex] = this.tempRow;
        } else {
          // 新增
          // console.log("数据111111", this.tableData);
          // console.log("11111111111",  Array.isArray(this.tableData) );
          if (this.tableData == null) {
              this.tableData = [];
              this.tableData.unshift({
                ...this.tempRow,
              });
            } else {
              this.tableData.unshift({
                ...this.tempRow,
              });
            }
          // console.log("数据2222222",this.tableData);
        }
        this.addPort(this.form.Id);
        // 关闭dialog
        this.dialogFormVisible = false;
      }
    },
    // 删除
    delRow(index, row) {
      if(this.form.Id == null){
        console.log("22222222", index, row);
      ElMessageBox.confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 删除成功
          var ind = this.tableData.findIndex((item) => item.Id === row.Id);
          //   console.log("下标",ind);
          this.tableData.splice(ind, 1);
          ElMessage({
            type: "success",
            message: "删除成功",
          });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "已取消删除",
          });
        });
      }else{
        console.log("22222222", index, row);
      ElMessageBox.confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 删除成功
          var ind = this.tableData.findIndex((item) => item.Id === row.Id);
          //   console.log("下标",ind);
          this.tableData.splice(ind, 1);
          this.addPort(this.form.Id);
          ElMessage({
            type: "success",
            message: "删除成功",
          });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "已取消删除",
          });
        });
      }
    },
    // 修改
    toEdit(index, row) {
      console.log("下标，内容", index, row);
      // 当前行的数据赋值给临时行
      this.tempRow = {
        ...row,
      };
      // 存储正在编辑的行
      this.editIndex = this.tableData.findIndex((item) => item.Id === row.Id);
      // 显示弹框
      this.dialogFormVisible = true;
      // 修改弹框标题
      this.dialogTitle = "编辑";
    },
    // 新增
    toAdd() {
      // 显示弹框
      this.dialogFormVisible = true;
      // 修改弹框标题
      this.dialogTitle = "添加";
      // 重置临时行
      this.tempRow = {};
    },
    //  获取野生动物数据
    async shuj() {
      let res = await this.$ajax.get(
        this.$appConfig.apiUrls.ywxtApi +
          "/api/YW/Getxsyswzxx?BJID=" +
          this.form.Id
      );
      //   console.log(res);
      //   console.log(123);
      this.tableData = res.data.Data;
    },
    //获取项目代码数据
    async getXmmc(queryString, cb) {
      let me = this;
      await this.$ajax
        .get(
          me.$appConfig.apiUrls.ywxtApi +
            "/api/YW/SearchXMDM?xmdm=" +
            queryString
        )
        .then(function (response) {
          if (response.data.IsSuccess) {
            let res = response.data.Data;
            let arr = [];
            if (res != null) {
              for (let a = 0; a < res.length; a++) {
                let speak = {
                  XMMC: "",
                  JSDW: "",
                  XZQHDM: "",
                  JSWZ: "",
                };
                for (let k1 in speak) {
                  for (let k2 in res[a]) {
                    if (k1 === k2) {
                      speak[k1] = res[a][k2];
                    }
                  }
                }
                speak.value = res[a].XMDM;
                speak.JSWZ = res[a].XMZL;
                //拿到form值  赋值
                arr.push(speak);
              }
            }
            me.restaurants.value = arr;
            var $cname = document.querySelector(".cname");
            me.restaurants.value.length > 0
              ? ($cname.style.display = "block")
              : "";
            let results = queryString
              ? me.restaurants.value.filter(me.createFilter(queryString))
              : me.restaurants.value;
            // console.log('获取', results);
            cb(results);
          } else {
            console.log(response.data.errorMessage);
          }
        });
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    handleSelect(item) {
      for (let k1 in this.form) {
        for (let k2 in item) {
          if (k1 === k2) {
            this.form[k1] = item[k2];
          }
        }
      }
      console.log(this.form);
      console.log("选中常用语：", item);
    },
    // 添加野生数据
    async addPort(id) {
      // console.log("sssssssss",id);
      await this.$ajax.post(
        this.$appConfig.apiUrls.ywxtApi + "/api/YW/Addxsyswzxx?BJID=" + id,
        this.tableData
      );

      // console.log("jjjjjjjjjj", s);
      // this.tableData = s.data.Data;
    },
    async onSubmit() {
      let me = this;
      if (me.checkform()) {
        this.saving = true;
        try {
          await this.$ajax
            .post(me.$appConfig.apiUrls.ywxtApi + "/api/YW/Addlahz", me.form)
            .then((response) => {
              if (response.data.IsSuccess) {
                me.form.Id = response.data.Data.instanceId;
                me.$store.state.AJH = me.form.AJH
                me.$store.state.XMDM = me.form.XMDM
                if(me.tableData == null){
                  return me.tableData = [];
                }else{
                  me.addPort(me.form.Id);
                }
                ElMessage({
                  type: "success",
                  message: "保存成功",
                });
                if (!me.$route.query.taskId) {
                  me.$router.handleClose(me.$route);
                }

                const row = JSON.stringify({
                  SBLX: this.form.SBLX,
                  Id: this.form.Id,
                  taskid: response.data.Data.taskId,
                  ZBH: this.form.ZBH,
                });
                if (!me.$route.query.taskId) {
                  this.$router.push({
                    path: "/buslist/flowinfo",
                    query: { row },
                  });
                }
              } else {
                ElMessage({
                  type: "info",
                  message: "立案失败!",
                });
                console.log(response.data.errorMessage);
              }
            });
        } catch (error) {
          console.log(error);
        } finally {
          this.saving = false;
        }
      }
    },
    updateSBLX(){
      this.$nextTick(() => {
        let id=toRaw(this.form.SBLX)
        if(this.$route.query.Id!=undefined&&this.$route.query.Id!=null){
          if(id!=this.$route.query.Id){
            this.$refs['baseform'].resetFields();
          }
          this.form.SBLX=this.$route.query.Id
          this.createzbh(this.form.SBLX)
        }
      });
    },
    //转化json
    switch() {
      let me = this;
      this.$ajax
        .get(me.$appConfig.apiUrls.flowApi + "/api/WorkFlow/GetWorkFlowList")
        .then(function (response) {
          if (response.data.IsSuccess) {
            for (var key in response.data.Data) {
              me.options4.push({ value: key, label: response.data.Data[key] });
            }
          } else {
            console.log(response.data.errorMessage);
          }
        });

      me.form.Id = this.$route.query.instanceId;
      if (me.form.Id === undefined) {
        me.sblxdisabled = false;
        me.sblxdisabled2 = false;
        if(me.$route.query.Id==undefined||me.$route.query.Id==null){
          me.isSblxDisabled = false;
        }
        me.savebuttonshow = true;
        me.form.Id = "";
      } else if(me.$route.query.taskId){
        me.sblxdisabled = false;
        me.savebuttonshow = true;
      } else {
        me.sblxdisabled = true;
        me.savebuttonshow = false;
      }
      this.$ajax
        .get(me.$appConfig.apiUrls.ywxtApi + "/api/YW/Getlahz?Id=" + me.form.Id)
        .then(function (response) {
          me.$store.commit("getFormData", response.data.Data);
          if (response.data.IsSuccess) {
            me.form = response.data.Data;
            me.$store.state.AJH = me.form.AJH;
            me.$store.state.XMDM = me.form.XMDM;
            if(me.$route.query.Id!=undefined&&me.$route.query.Id!=null){
              me.form.SBLX=me.$route.query.Id
              me.createzbh(me.form.SBLX)
            }
            me.$ajax
              .post(
                me.$appConfig.apiUrls.flowApi +
                  "/api/WorkFlowStep/GetBeforeSteps?taskId=" +
                  me.$route.query.taskId
              )
              .then(function (response) {
                if (response.data.IsSuccess) {
                  if (
                    response.data.Data.length === 0 &&
                    me.$store.state.userInfo.UserId === me.form.JJR
                  ) {
                    me.sblxdisabled = false;
                    me.savebuttonshow = true;
                  }
                  //   获取野生动物数据方法
                  me.shuj();
                } else {
                  console.log(response.data.errorMessage);
                }
              });
          } else {
            ElMessage({
              type: "info",
              message: "初始化表单失败",
            });
          }
        });
    },
    checkform() {
      let b = true;
      let f = this.form;
      if (b) b = this.checkinput(f.ZBH, "总编号");
      if (b) b = this.checkinput(f.AJH, "案卷号");
      if (b) b = this.checkinput(f.SSFJ, "所属分局");
      if (b) b = this.checkinput(f.SBLX, "申报类型");
      // if (this.$appConfig.checkxmdm.includes(f.SBLX)) {
      //   if (b) b = this.checkinput(f.XMDM, "项目代码");
      // }
      if (b) b = this.checkinput(f.XMMC, "目的");
      // if (b) b = this.checkinput(f.JSDW, "建设单位");
      if (b) b = this.checkinput(f.JSWZ, "地点");
      if (b) b = this.checkinput(f.LXR, "申请人");
      if (b) b = this.checkinput(f.SJH, "手机号");
      if (b && f.ZJHM) b = this.checkID(f.ZJHM);
      if (b && f.DZYJ) b = this.checkEmail(f.DZYJ);

      return b;
    },
    checkinput(val, mess) {
      if (!val || val.trim().length < 1) {
        ElMessage({
          type: "info",
          message: "请输入" + mess,
        });
        return false;
      } else {
        return true;
      }
    },
    async getssfj() {
      let me = this;
      await this.$ajax
        .get(
          me.$appConfig.apiUrls.ywxtApi +
            "/api/Dictionary/GetDictionary?code_class=1"
        )
        .then(function (response) {
          if (response.data.IsSuccess) {
            response.data.Data.forEach(function (value) {
              me.options1.push({ value: value.code, label: value.code_value });
            });
          } else {
            console.log(response.data.errorMessage);
          }
        });
    },
    async getxzqh() {
      let me = this;
      await this.$ajax
        .get(
          me.$appConfig.apiUrls.ywxtApi +
            "/api/Dictionary/GetDictionary?code_class=3"
        )
        .then(function (response) {
          if (response.data.IsSuccess) {
            response.data.Data.forEach(function (value) {
              me.options5.push({ value: value.code, label: value.code_value });
            });
          } else {
            console.log(response.data.errorMessage);
          }
        });
    },
    async getuser() {
      let user = await mgr.getUser();
      this.form.JJRXM = user.profile.name;
    },
    createzbh(val) {
      let me = this;
      this.$ajax
        .get(
          me.$appConfig.apiUrls.flowApi +
            "/api/WorkFlowExecute/GetNextInstanceNo?flowId=" +
            val
        )
        .then(function (response) {
          if (response.data.IsSuccess) {
            me.form.ZBH = response.data.Data;
          } else {
            console.log(response.data.errorMessage);
          }
        });
      this.$ajax
        .get(
          me.$appConfig.apiUrls.ywxtApi +
            "/api/Tool/GetNextNumber?ruleId=AJH-" +
            val
        )
        .then(function (response) {
          if (response.data.IsSuccess) {
            me.form.AJH = response.data.Data;
          } else {
            console.log(response.data.errorMessage);
          }
        });
    },
    async getzjlx() {
      let me = this;
      await this.$ajax
        .get(me.$appConfig.apiUrls.ywxtApi + "/api/Dictionary/Getzjlx")
        .then(function (response) {
          if (response.data.IsSuccess) {
            me.options3 = response.data.Data;
          } else {
            console.log(response.data.errorMessage);
          }
        });
    },
    checkMobile() {
      const regMobile =
        /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
      if (regMobile.test(this.form.SJH)) {
        return true;
      }
      ElMessage({
        type: "info",
        message: "请输入正确的手机号",
      });
      return false;
    },
    checkEmail() {
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
      if (regEmail.test(this.form.DZYJ)) {
        return true;
      }
      ElMessage({
        type: "info",
        message: "请输入正确的邮箱",
      });
      return false;
    },
    checkID() {
      let regID = /^\d{5,}$/;
      if (this.form.ZJMC === "104") {
        regID = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      }
      if (regID.test(this.form.ZJHM)) {
        return true;
      } else {
        ElMessage({
          type: "info",
          message: "请输入正确的证件号码",
        });
        return false;
      }
    },

  },
  activated() {
    this.updateSBLX();
  },
  mounted() {
    this.switch();
    this.getssfj();
    this.getxzqh();
    this.getzjlx();
    this.restaurants.value = this.loadAll;
  },
  beforeRouteLeave(to, from) {
    if (this.$router.closeTag && this.$router.closeTag.href === from.href) {
      // console.log('执行了关闭当前的');
      this.$refs["baseform"].resetFields();
      this.$router.closeTag = {};
      this.form.Id = "";
      this.tableData = [];
    }
  },
};
</script>

<style scoped>
.has-gutter > tr th {
  color: black;
  text-align: center;
}
.el-table th > .cell {
  text-align: center;
}
.wr-else {
  width: calc(100% - 76px);
}
.edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-form {
  width: 100%;
  overflow: hidden;
}
.input-width {
  width: 100%;
}
.wrap-edit {
  height: 100%;
  overflow-y: scroll;
}
.wrap-edit .edit-header {
  margin-bottom: 15px;
}
:deep(.el-autocomplete) {
  width: 100%;
}
:deep(.el-form--label-left .asterisk .el-form-item__label)::after {
  content: "*";
  color: red;
}
</style>
<style>
.cname {
  display: none;
  transition: all 0.3s ease 0s;
}
.saving-tip {
  background: #edb868 !important;
}
.saving-tip .el-popper__arrow::before {
  background: #edb868 !important;
}
</style>
